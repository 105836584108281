<template>
  <div width="100"  class="mt-12">
      <v-row >
          <v-layout  justify-space-between>

            <v-col cols="12" sm="12" md="7" lg="6" v-if="verificationFailed">
              <v-layout  justify-start :class=" $vuetify.breakpoint.mdAndUp? 'mt-12 pt-12 ml-12 pl-12' : 'ma-2'">
                  <h1>Oops! Verification failed!</h1><br>
                 
            </v-layout>
            
             <p :class=" $vuetify.breakpoint.mdAndUp? 'mt-4 ml-12 pl-12' : 'ma-2'" style="font-size:18px; font-weight:400;"  > Kindly retry verifying by clicking the link sent to your registered mail or you can paste the verification code sent to your registered mail in the textbox below.</p>
            <br>
            <v-layout justify-start :class="$vuetify.breakpoint.mdAndUp? 'ml-12 pl-12' : 'ml-2 mr-5'" row>
                  <v-text-field solo dense :class="$vuetify.breakpoint.mdAndUp? 'mr-10': 'mr-2'" outlined placeholder="Paste the verification code here" v-model="verificationCode" ></v-text-field>
                  <v-btn    color="primary" @click="verifyUser" >Verify</v-btn>
            </v-layout>
            <v-layout justify-start :class="$vuetify.breakpoint.mdAndUp? 'ml-12 pl-12' : 'ma-2'" column>
                <p style="font-size:18px; font-weight:400; " >Did not recieve our email? <span class="button ml-2" style="font-size:18px; font-weight:500;  text-decoration: underline;"  @click="() => {resendDialog = true;}" > Resend-email</span></p>
            </v-layout>

            <br><br><br ><br ><br >

            <v-layout justify-start :class="$vuetify.breakpoint.mdAndUp? 'mt-12 ml-12 pl-12' : 'ma-2'" column>
                <p style="font-size:20px; font-weight:600; color: #808080;" >Need any help?</p>
                <p style="font-size:18px; font-weight:400;color: #808080;" class="mt-n3">Feel free to send us any feedback or bug reports at feedback/apijug.com</p>
            </v-layout>


 
          </v-col>
          <v-col cols="12" lg="6" v-else >
              <v-layout justify-start :class=" $vuetify.breakpoint.mdAndUp? 'mt-12 pt-12 ml-12 pl-12' : 'ma-2'">
                  <h1>Hello {{userInfo.person_name}}, Welcome! </h1><br>
                 
            </v-layout>
            
             <p :class=" $vuetify.breakpoint.mdAndUp? 'mt-4 ml-12 pl-12' : 'ma-2'" style="font-size:18px; font-weight:400;" >Thank you for signing up with us. We have sent you an email at <span class="primary--text"> {{ userInfo.email}} </span>Please verify your email address to continue.</p>
            <br>
            <v-layout justify-start :class="$vuetify.breakpoint.mdAndUp? 'ml-12 pl-12' : 'ma-2'" row>
                  <v-text-field solo dense :class="$vuetify.breakpoint.mdAndUp? 'mr-10': 'mr-2'" outlined placeholder="Paste the verification code here" v-model="verificationCode" ></v-text-field>
                  <v-btn    color="primary" @click="verifyUser" >Verify</v-btn>
            </v-layout>
            <v-layout justify-start :class="$vuetify.breakpoint.mdAndUp? 'ml-12 pl-12' : 'ma-2'" column>
                <p style="font-size:18px; font-weight:400; " >Did not recieve our email? <span class="button ml-2" style="font-size:18px; font-weight:500;  text-decoration: underline;" @click="() => {resendDialog = true}" > Resend-email</span></p>
            </v-layout>

            <br><br><br ><br><br>

            <v-layout justify-start :class="$vuetify.breakpoint.mdAndUp? 'mt-12 ml-12 pl-12' : 'ma-2'" column>
                <p style="font-size:20px; font-weight:600; color: #808080;" >Need any help?</p>
                <p style="font-size:18px; font-weight:400;color: #808080;" class="mt-n3">Feel free to send us any feedback or bug reports at feedback/apijug.com</p>
            </v-layout>


 
          </v-col>
          <v-col cols="5" md="4" lg="5" v-show="$vuetify.breakpoint.mdAndUp">
              <v-layout justify-end align-content-end class="pa-n12"  >
                  
              <img src="@/assets/verificationscreen.svg" width="100%"  >
                
              </v-layout>
          </v-col>
          </v-layout>
      </v-row>

      <resendDialog :show="resendDialog" @close="resendDialog = false"></resendDialog>
  </div>
</template>

<script>
//import ResendEmailDialog from '../components/resendEmailDialog.vue';
const resendDialog = () => import('../components/resendEmailDialog.vue')

export default {

  metaInfo: {
      vmid: 'main',
      title: 'Verification',
      //titleTemplate: null,
      content: 'This is an application for subscription of various apis!'
    },

  data:() => ({
    verificationCode : '',
    resendDialog: false,
  }),

components: {
resendDialog

},


beforeCreate: function(){
  var code = this.$route.params.verificationCode;
  console.log(code)
  this.$store.dispatch('verifyUser',code)

},

methods: {

  verifyUser: function(){
    this.$store.dispatch('verifyUser', this.verificationCode)
  }

},

computed: {
    userInfo: function(){
      return this.$store.state.auth.userInfo;
    },

    verificationFailed: function(){
      return this.$store.state.auth.verificationFailed;
    }
  },

}
</script>

<style>

</style>